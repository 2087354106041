import { reactive, ref } from "vue";
import useMessage from "@/hooks/useMessage";
import { list } from "@/api/parking/monthlyRentalPackage";
import { listAll } from "../../api/parking/parkingLotApi";

const useMonthlyRentalPackage = () => {
  const { showErr } = useMessage();
  const loading = ref(false);
  const dataList = reactive({ list: [] });
  const total = ref(0);
  const parkingLotList = reactive([]);

  const monthlyRentalPackageVo = reactive({
    id: "",
    packageName: "",
    packagePayAmount: "",
    packageType: "",
    parkingLotId: [],
    parkingLotName: null,
    status: null,
  });

  const packageTypes =ref([
    { value: 1, label: "月租" },
    { value: 2, label: "季度" },
    { value: 3, label: "年租" },
]);

  const columns = [
    {
      title: "序号",
      align: "center",
      width: 50,
      customRender: ({ index }) => {
        return index + 1;
      },
    },
    {
      title: "套餐Id",
      align: "center",
      width: 200,
      dataIndex: "id",
    },
    {
      title: "停车场名称",
      align: "center",
      width: 200,
      dataIndex: "parkingLotName",
    },
    {
      title: "套餐名称",
      align: "center",
      width: 150,
      dataIndex: "packageName",
    },
    {
      title: "套餐类型",
      align: "center",
      width: 80,
      dataIndex: "packageType",
      // eslint-disable-next-line no-unused-vars
      slots: { customRender: "packageType" },
    },
    {
      title: "套餐金额",
      align: "center",
      width: 150,
      dataIndex: "packagePayAmount",
      slots: { customRender: "packagePayAmount" },
    },
    {
      title: "操作",
      align: "center",
      width: "240px",
      slots: { customRender: "operation" },
    },
  ];
  const money = m => {
    if (typeof m !== "undefined") {
      return (m / 100).toFixed(2);
    }
    return "";
  };
  const moneyZ = (val) => {
    if (typeof val !== "undefined") {
      return val * 100;
    }
    return 0;
  };
  const getParkingLotList = async () => {
    try {
      let { data } = await listAll();
      parkingLotList.push(...data);
    } catch (e) {
      showErr(e);
    }
  };
  const loadData = async (page, pageSize) => {
    loading.value = true;
    try {
      let res = await list(params, page, pageSize);
      dataList.list = res.data;
      total.value = res.total;
      loading.value = false;
    } catch (e) {
      loading.value = false;
      await showErr(e);
    }
  };



  const params = reactive({
    id: "",
    packageName: "",
  });

  return {
    loading,
    params,
    dataList,
    total,
    columns,
    loadData,
    getParkingLotList,
    monthlyRentalPackageVo,
    packageTypes,
    parkingLotList,
    money,
    moneyZ,
  };
};
export default useMonthlyRentalPackage;
